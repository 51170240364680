// import Footer from "@components/Footer";
import {
  Box,
  Flex,
  IconButton,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { BsQuestionCircle } from 'react-icons/bs';
import { MdArrowBack } from 'react-icons/md';

export default function MainLayout({
  title,
  showQuestion = true,
  hasBoxShadow = true,
  bgColor = '#DC3428',
  hasHeader = true,
  children,
}: {
  title?: string;
  showQuestion?: boolean;
  hasBoxShadow?: boolean;
  bgColor?: string;
  hasHeader?: boolean;
  children: any;
}) {
  const router = useRouter();

  return (
    <Box minH={'100vh'}>
      {hasHeader && (
        <Flex px={[5, 5, 5, 14, 28]} top="0" height="20" alignItems="center">
          <IconButton
            aria-label="Voltar"
            icon={<MdArrowBack />}
            variant="ghost"
            fontSize="30px"
            onClick={() => {
              router.push('/');
            }}
          />
          <Spacer />
          {title && (
            <VStack>
              <Box textAlign={'center'}>
                <Text
                  fontFamily={'mainfont'}
                  fontWeight={700}
                  textTransform={'uppercase'}
                  fontSize={['sm', 'sm', 'xl']}
                >
                  Promoção {process.env.NEXT_PUBLIC_APP_NAME}
                </Text>
                <Text
                  fontWeight={'700'}
                  fontFamily={'mainfont'}
                  fontSize={['sm', 'sm', 'xl']}
                >
                  {title}
                </Text>
              </Box>
              <Box></Box>
              <Spacer></Spacer>
            </VStack>
          )}

          <Spacer />
          {showQuestion ? (
            <IconButton
              aria-label="FAQ"
              icon={<BsQuestionCircle />}
              variant="ghost"
              fontSize="30px"
              onClick={() => {
                router.push('/faq');
              }}
            />
          ) : (
            <Box width={'40px'}></Box>
          )}
        </Flex>
      )}

      <Flex align={'center'} justify={'center'}>
        <Stack
          spacing={8}
          mx={'auto'}
          py={hasHeader ? [2, 12] : [8, 20]}
          px={[2,6]}
        >
          <Box
            rounded={'lg'}
            bg={bgColor}
            boxShadow={hasBoxShadow ? 'lg' : ''}
            p={[2,4]}
            color={"white"}
          >
            {children}
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
}
