import { ThemeNames } from '@context/themeContext';
import { useTheme } from '@hooks/useTheme';
import {
  Button,
  Container,
  Heading,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { memo, useEffect } from 'react';

export default memo(ErrorModule);

interface ErrorModuleProps {
  status?: number;
  customMessage?: string;
}

function ErrorModule({
  status,
  customMessage = 'Humm... algo deu errado',
}: ErrorModuleProps) {
  const { push } = useRouter();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme(ThemeNames.backgroundGrey);
  }, []);

  const renderStatusText = () => {
    switch (status) {
      case 404:
        return 'Página não encontrada!';
      case 406:
      case 403:
        return 'Você não tem acesso';
      case 500:
        return 'Algo deu errado';
      default:
        return '';
    }
  };

  return (
    <Container centerContent>
      <Heading fontFamily={'AMX'} fontWeight={'800'} textAlign={'center'}>
        {renderStatusText()}
      </Heading>
      <VStack spacing={[6, 16]} pt={[4,6]}>
        <Image
          src={'/imgs/logo.png'}
          height={['80px', '100px']}
          width={['250px', '300px']}
        />
        <Text fontSize={['md', 'xl']} textAlign={'center'} color={'white'}>
          {status ? (
            <p style={{ fontFamily: 'AMX', fontWeight: '700' }}>
              <span>
                {status == 404
                  ? 'Ops, não tem nada aqui... '
                  : status == 403
                  ? 'Você não tem permissão para acessar esta área'
                  : 'Humm... algo deu errado'}
              </span>
              {status >= 400 && status < 500 ? (
                'Mas você pode saber mais acessando a home'
              ) : (
                <span>
                  Tente acessar novamente em alguns instantes. Se o erro
                  persistir, entre em contato conosco pelo email
                  <br />
                  <u>contato@maquinadepromos.com.br</u>
                </span>
              )}
            </p>
          ) : (
            <Text>{customMessage}</Text>
          )}
        </Text>
        <Button
          variant="lpBannerButton"
          colorScheme={'yellow'}
          onClick={() => push('/')}
          w={'full'}
          h={10}
          size={['md', 'xl']}
        >
          <Text>Home</Text>
        </Button>
      </VStack>
    </Container>
  );
}
